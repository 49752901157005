import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import './stackedBarChart.css';

const StackedBarChart = ({ state, ageGroup, year, contactMode }) => {
  const [chartOptions, setChartOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawData, setRawData] = useState(null);

  useEffect(() => {
    if (state) {
      fetchData(state, ageGroup, year, contactMode);
    }
  }, [state, ageGroup, year, contactMode]);

  const fetchData = async (state, ageGroup, year, contactMode) => {
    setLoading(true);
    setError(null);
    setRawData(null);
    console.log("Fetching data with params:", { state, ageGroup, year, contactMode });
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/scamreports/stacked_bar_chart/`, {
        params: {
          state,
          year,
          contactMode,
        },
      });
      
      console.log("API Response:", response.data);
      
      const data = response.data.chart_data;
      setRawData(data);
      if (Array.isArray(data) && data.length > 0) {
        prepareChartOptions(data);
      } else {
        console.warn("Received empty or non-array data");
        setChartOptions({});
      }
    } catch (error) {
      console.error("API Error:", error);
      setError(`Error fetching data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartOptions = (data) => {
    console.log("Preparing chart options with data:", data);
    if (!Array.isArray(data) || data.length === 0) {
      console.warn("Invalid data format in prepareChartOptions");
      setChartOptions({});
      return;
    }

    const categories = data.map(item => item.age_group);
    const contactModes = Object.keys(data[0]).filter(key => key.startsWith('contact_mode_'));

    if (contactModes.length === 0) {
      console.warn("No contact modes found in data");
      setChartOptions({});
      return;
    }

    const series = contactModes.map((mode, index) => ({
      name: mode.replace('contact_mode_', ''),
      data: data.map(item => item[mode]),
      color: ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57'][index % 5],
    }));

    setChartOptions({
      chart: {
        type: 'bar',
        height: 600,
      },
      title: {
        text: 'Scam Reports by Contact Mode Across Age Groups',
      },
      xAxis: {
        categories,
        title: {
          text: 'Age Groups',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Number of Reports',
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>',
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      series,
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!chartOptions.series || chartOptions.series.length === 0) {
    return (
      <div>
        <p>No data available for the selected parameters:</p>
        <ul>
          <li>State: {state}</li>
          <li>Age Group: {ageGroup}</li>
          <li>Year: {year}</li>
          <li>Contact Mode: {contactMode}</li>
        </ul>
        <p>Raw data received: {JSON.stringify(rawData)}</p>
      </div>
    );
  }

  return (
    <div className="chart-container">
      <div className="explanation-text">
        <h2>Scam Reports by Contact Mode & Age Groups</h2>
        <p>
          This chart reveals the most common ways scammers reach out — 
          whether it's through phone, email, social media, and more—broken down by age groups. 
          Stay one step ahead by knowing their tactics!
        </p>
      </div>
    
      <div className="chart">
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </div>
  );
};

export default StackedBarChart;