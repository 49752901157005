import React, { useState } from 'react';
import './Quiz.css';

const Quiz = () => {
    const questions = [
        {
            "q_no": 1,
            "q_description": "You should always trust job offers that come from well-known companies, even if they reach you via social media.",
            "q_answer": "B. FALSE",
            "q_category": "Job Scams",
            "options": [
                {
                    "id": 1,
                    "option": "TRUE",
                    "option_description": "Your answer is not correct. Scammers often impersonate well-known companies on social media."
                },
                {
                    "id": 2,
                    "option": "FALSE",
                    "option_description": "Your answer is correct. Scammers often impersonate well-known companies on social media."
                }
            ]
        },
        {
            "q_no": 2,
            "q_description": "It's common for legitimate job offers to require you to pay for training or materials upfront.",
            "q_answer": "B. FALSE",
            "q_category": "Employment Scams",
            "options": [
                {
                    "id": 3,
                    "option": "TRUE",
                    "option_description": "Your answer is not correct. Legitimate employers do not ask for upfront payments for job offers."
                },
                {
                    "id": 4,
                    "option": "FALSE",
                    "option_description": "Your answer is correct. Legitimate employers do not ask for upfront payments for job offers."
                }
            ]
        },
        {
            "q_no": 3,
            "q_description": "You got the following message on your mobile:\n\nShould you click on the link?",
            "q_answer": "B. NO",
            "q_category": "Message Based Scams",
            "image": "/q3.png",
            "options": [
                {
                    "id": 5,
                    "option": "YES",
                    "option_description": "Your answer is not correct. This is likely a scam message, and the link itself may be malicious."
                },
                {
                    "id": 6,
                    "option": "NO",
                    "option_description": "Your answer is correct. This is likely a scam message, and the link itself may be malicious."
                }
            ]
        },
        {
            "q_no": 4,
            "q_description": "You received a WhatsApp link on your phone along with a message:\n\nShould you join the WhatsApp group?",
            "q_answer": "B. NO",
            "q_category": "Message Based Scams",
            "image": "/q4.png",
            "options": [
                {
                    "id": 7,
                    "option": "YES",
                    "option_description": "Your answer is not correct. This is likely a scam message, and the link itself may be malicious."
                },
                {
                    "id": 8,
                    "option": "NO",
                    "option_description": "Your answer is correct. This is likely a scam message, and the link itself may be malicious."
                }
            ]
        },
        {
            "q_no": 5,
            "q_description": "What is the appropriate response to an unsolicited job offer message?",
            "q_answer": "D. Please send me the details from your official mail ID",
            "q_category": "Message Based Scams",
            "image": "/q5.png",
            "options": [
                {
                    "id": 9,
                    "option": "No response",
                    "option_description": "It is most likely a scam, but if there is even a slim chance it can be legit, asking for details from an official email ID discourages the scammers."
                },
                {
                    "id": 10,
                    "option": "Please provide more details over the message",
                    "option_description": "You should proceed with caution while responding to such a message. It should be from their official email ID."
                },
                {
                    "id": 11,
                    "option": "I am not interested",
                    "option_description": "Such responses encourage scammers to further contact you. Asking for details from their official email ID is more appropriate."
                },
                {
                    "id": 12,
                    "option": "Please send me the details from your official mail ID",
                    "option_description": "Your answer is correct. This approach discourages scammers from proceeding."
                }
            ]
        },
        {
            "q_no": 6,
            "q_description": "Should you reply with 'Yes' or 'Interested' to a job offer message that seems suspicious?",
            "q_answer": "B. NO",
            "q_category": "Message Based Scams",
            "image": "/q6.png",
            "options": [
                {
                    "id": 13,
                    "option": "YES",
                    "option_description": "The red flags are informal language and unrealistic salary expectations."
                },
                {
                    "id": 14,
                    "option": "NO",
                    "option_description": "Your answer is correct. The red flags are informal language and unrealistic salary expectations."
                }
            ]
        },
        {
            "q_no": 7,
            "q_description": "Which of the following is a legitimate website?",
            "q_answer": "A. https://www.apple.com",
            "q_category": "Malicious Link Scam",
            "options": [
                {
                    "id": 15,
                    "option": "https://www.apple.com",
                    "option_description": "Your answer is correct. It uses a secure HTTPS protocol."
                },
                {
                    "id": 16,
                    "option": "http://www.apple.com",
                    "option_description": "It is not considered secure since it uses HTTP instead of HTTPS."
                }
            ]
        },
        {
            "q_no": 8,
            "q_description": "You saw this ad on a social media platform. What should be your action?",
            "q_answer": "C. Report the post as scam",
            "q_category": "Social Media Platform Scam",
            "image": "/q8.png",
            "options": [
                {
                    "id": 17,
                    "option": "Click on the link provided",
                    "option_description": "The link is likely a scam. Always report such posts."
                },
                {
                    "id": 18,
                    "option": "Ignore the post",
                    "option_description": "You should report such posts to prevent others from falling victim."
                },
                {
                    "id": 19,
                    "option": "Report the post as scam",
                    "option_description": "Your answer is correct. Reporting helps prevent others from falling victim."
                }
            ]
        }
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showExplanation, setShowExplanation] = useState(false);
    const [score, setScore] = useState(0);
    const [categoryScores, setCategoryScores] = useState({});
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);

    const handleOptionSelect = (optionId) => {
        setSelectedOption(optionId);
        setShowExplanation(true);

        const currentQuestion = questions[currentQuestionIndex];
        const correctOption = currentQuestion.options.find(
            option => option.option === currentQuestion.q_answer.split('. ')[1]
        );

        if (optionId === correctOption.id) {
            setScore(prevScore => prevScore + 1);
            setCategoryScores(prevScores => {
                const category = currentQuestion.q_category;
                const newScore = (prevScores[category] || 0) + 1;
                return { ...prevScores, [category]: newScore };
            });
        }
    };

    const handleNextQuestion = () => {
        setShowExplanation(false);
        setSelectedOption(null);
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizCompleted(true);
        }
    };

    const currentQuestion = questions[currentQuestionIndex];

    if (isQuizCompleted) {
        const totalQuestionsByCategory = questions.reduce((acc, question) => {
            acc[question.q_category] = (acc[question.q_category] || 0) + 1;
            return acc;
        }, {});

        const allCategories = [
            "Job Scams", 
            "Employment Scams", 
            "Message Based Scams", 
            "Malicious Link Scam", 
            "Social Media Platform Scam"
        ];

        return (
            <div className="quiz-container">
                <div className="results-container">
                    <h2>Quiz Completed!</h2>
                    <p>Your Score: {score} out of {questions.length}</p>

                    <div className="category-performance">
                        <h3>Category-wise Performance:</h3>
                        <ul>
                            {allCategories.map(category => (
                                <li key={category}>
                                    {category}: {categoryScores[category] || 0} out of {totalQuestionsByCategory[category] || 0}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="insights-feedback">
                        <h3>Insights and Feedback:</h3>
                        <ul>
                            {allCategories.map(category => {
                                const correct = categoryScores[category] || 0;
                                const total = totalQuestionsByCategory[category] || 0;
                                const percentage = total > 0 ? (correct / total) * 100 : 0;
                                let feedback;

                                if (percentage === 100) {
                                    feedback = "Excellent! You've mastered this category.";
                                } else if (percentage >= 75) {
                                    feedback = "Good job! You have a strong understanding, but there's still room for improvement.";
                                } else {
                                    feedback = "Needs improvement. Consider reviewing this category to better understand the concepts.";
                                }

                                return (
                                    <li key={category}>
                                        {category}: {feedback}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="quiz-container">
            <div className="progress-bar-container">
                <div className="progress-bar">
                    <div 
                        className="progress-bar-fill" 
                        style={{ width: `${((currentQuestionIndex + 1) / questions.length) * 100}%` }}
                    />
                </div>
                <p>{currentQuestionIndex + 1} of {questions.length} questions completed</p>
            </div>
            <h2>Question {currentQuestion?.q_no || 'Loading...'}</h2>
            {currentQuestion?.image && <img src={currentQuestion.image} alt={`Question ${currentQuestion.q_no}`} />}
            <p>{currentQuestion?.q_description}</p>
            <div className="options-container">
                {currentQuestion?.options.map(option => (
                    <div key={option.id} className="option">
                        <button 
                            className={`option-button ${selectedOption === option.id ? 'selected' : ''}`}
                            onClick={() => handleOptionSelect(option.id)}
                            disabled={showExplanation}
                        >
                            {option.option}
                        </button>
                        {showExplanation && selectedOption === option.id && (
                            <p className="option-description">{option.option_description}</p>
                        )}
                    </div>
                ))}
            </div>
            {showExplanation && (
                <div className="button-container">
                    <button className="next-question-button" onClick={handleNextQuestion}>
                        {currentQuestionIndex < questions.length - 1 ? 'Next Question' : 'Finish Quiz'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Quiz;
