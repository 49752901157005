import React, { useState, useEffect } from "react";
import "./ReportScam.css";
import { FaExternalLinkAlt } from 'react-icons/fa';  
import NextStepButton from '../components/NextStepButton'

function ReportScam() {
  const [selectedContent, setSelectedContent] = useState(1);

  // State for each section visibility
  const [visibleSections, setVisibleSections] = useState({
    gatherInfo: false,
    bankInfo: false,
    passwordInfo: false,
    documentInfo: false,
    reportInfo: false,
    scamwatch: false,
    ASIC: false,
    servicesAustralia: false,
    ATO: false,
    localPolice: false,
    idcare: false,
    lifeline: false,
    antiScamCentre: false,
    scamwatchCommunity: false,
  });

  // Reset all dropdowns when the module changes
  useEffect(() => {
    setVisibleSections({
      gatherInfo: false,
      bankInfo: false,
      passwordInfo: false,
      documentInfo: false,
      reportInfo: false,
      scamwatch: false,
      ASIC: false,
      servicesAustralia: false,
      ATO: false,
      localPolice: false,
      idcare: false,
      lifeline: false,
      antiScamCentre: false,
      scamwatchCommunity: false,
    });
  }, [selectedContent]);

  // Toggle visibility of section
  const handleSectionClick = (section) => {
    setVisibleSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="page-container">
      {/* Header Section */}
      <div id="header-section" className="header-section">
        <h1>Got Scammed? Don't Worry, We're Here to Help!</h1>
        <p>You're not alone in this journey. Many have faced similar challenges, and together we can navigate the path to recovery.</p>
        <button id="start-button"
  className="start-button"
  onClick={() => {
    const targetElement = document.getElementById("start-button");
    if (targetElement) {
      console.log("Element found:", targetElement); // Debugging to check if the element is detected
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error("Element not found!");
    }
  }}
>
  Get Started
</button>



      </div>

      {/* Button Section */}
      <div id="button-section2" className="button-section2">
        <button className="action-button" onClick={() => setSelectedContent(1)}>Report Your Scam</button>
        <button className="action-button" onClick={() => setSelectedContent(2)}>Connect with Authorities</button>
        <button className="action-button" onClick={() => setSelectedContent(3)}>Join a Support Community</button>
      </div>

      {/* Content Section */}
      <div id="content-section" className="content-section">
        {selectedContent === 1 && (
          <>
            {/* Gather all relevant information */}
            <div id="gather-info-section" className={`content-item ${visibleSections.gatherInfo ? 'active' : ''}`}>
  <strong onClick={() => handleSectionClick('gatherInfo')}>
    Gather all relevant information
  </strong>
  {visibleSections.gatherInfo && (
    <p>Collect emails, text messages, phone numbers, websites, and any other relevant details about the scam.</p>
  )}
</div>


            {/* Contact your bank */}
            <div className={`content-item ${visibleSections.bankInfo ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('bankInfo')}>
                Contact your bank or financial institution
              </strong>
              {visibleSections.bankInfo && (
                <p>If financial information was compromised, immediately contact your bank to freeze accounts or cancel cards.</p>
              )}
            </div>

            {/* Change passwords */}
            <div className={`content-item ${visibleSections.passwordInfo ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('passwordInfo')}>
                Change passwords for affected accounts
              </strong>
              {visibleSections.passwordInfo && (
                <p>Make sure to update your passwords immediately to secure your accounts and prevent further unauthorized access.</p>
              )}
            </div>

            {/* Document scam details */}
            <div className={`content-item ${visibleSections.documentInfo ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('documentInfo')}>
                Document scam details
              </strong>
              {visibleSections.documentInfo && (
                <p>Keep a detailed record of the scam, including dates, times, and any communication you had with the scammer.</p>
              )}
            </div>

            {/* File a report */}
            <div className={`content-item ${visibleSections.reportInfo ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('reportInfo')}>
                File a report with authorities
              </strong>
              {visibleSections.reportInfo && (
                <p>Reporting the scam helps protect others and can lead to investigations that hold scammers accountable.</p>
              )}
            </div>
          </>
        )}

        {selectedContent === 2 && (
          <>
            {/* Scamwatch */}
            <div className={`content-item ${visibleSections.scamwatch ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('scamwatch')}>
                Scamwatch
              </strong>
              {visibleSections.scamwatch && (
                <>
                  <p>Report any suspicious activity.</p>
                  <a href="https://www.scamwatch.gov.au/report-a-scam" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* ASIC */}
            <div className={`content-item ${visibleSections.ASIC ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('ASIC')}>
                ASIC
              </strong>
              {visibleSections.ASIC && (
                <>
                  <p>For financial scams.</p>
                  <a href="https://asic.gov.au/about-asic/contact-us/how-to-complain/report-misconduct-to-asic/" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* Services Australia */}
            <div className={`content-item ${visibleSections.servicesAustralia ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('servicesAustralia')}>
                Services Australia
              </strong>
              {visibleSections.servicesAustralia && (
                <>
                  <p>For Medicare, welfare, and child support fraud.</p>
                  <a href="https://www.servicesaustralia.gov.au/reporting-fraud" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* ATO */}
            <div className={`content-item ${visibleSections.ATO ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('ATO')}>
                ATO
              </strong>
              {visibleSections.ATO && (
                <>
                  <p>For tax or superannuation fraud.</p>
                  <a href="https://www.ato.gov.au/general/online-services/identity-security/report-a-scam/" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* Local Police */}
            <div className={`content-item ${visibleSections.localPolice ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('localPolice')}>
                Local Police
              </strong>
              {visibleSections.localPolice && <p>Call 131 444 or visit your state police website.</p>}
            </div>
          </>
        )}

        {selectedContent === 3 && (
          <>
            {/* IDCARE */}
            <div className={`content-item ${visibleSections.idcare ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('idcare')}>
                IDCARE
              </strong>
              {visibleSections.idcare && (
                <>
                  <p>National identity and cyber support service. Call 1800 595 160 for help with identity compromise.</p>
                  <a href="https://www.idcare.org" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* Lifeline */}
            <div className={`content-item ${visibleSections.lifeline ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('lifeline')}>
                Lifeline
              </strong>
              {visibleSections.lifeline && (
                <>
                  <p>24/7 crisis support. Call 13 11 14 or use their online chat.</p>
                  <a href="https://www.lifeline.org.au" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* National Anti-Scam Centre */}
            <div className={`content-item ${visibleSections.antiScamCentre ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('antiScamCentre')}>
                National Anti-Scam Centre
              </strong>
              {visibleSections.antiScamCentre && (
                <>
                  <p>Provides resources to help avoid scams.</p>
                  <a href="https://www.nasc.gov.au" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>

            {/* Scamwatch Community */}
            <div className={`content-item ${visibleSections.scamwatchCommunity ? 'active' : ''}`}>
              <strong onClick={() => handleSectionClick('scamwatchCommunity')}>
                Scamwatch Community
              </strong>
              {visibleSections.scamwatchCommunity && (
                <>
                  <p>Offers updates on current scams and educational resources. Subscribe to their scam alert emails for the latest information.</p>
                  <a href="https://www.scamwatch.gov.au" target="_blank" rel="noopener noreferrer">
                    <FaExternalLinkAlt /> Click here to their website
                  </a>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <NextStepButton 
                nextPage="/trivia" 
                buttonText="Explore Job Scam Awareness" 
            />
    </div>
  );
}

export default ReportScam;
