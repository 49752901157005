import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ContactModeIcons.css';
import { FaShieldAlt } from 'react-icons/fa';

const ContactModeIcons = ({ selectedStateName, selectedAgeGroup, selectedYear }) => {
  const [topContactModes, setTopContactModes] = useState({});

  useEffect(() => {
    if (selectedStateName) {
      axios.get(`${process.env.REACT_APP_API_URL}/scamreports/stacked_bar_chart/`, {
        params: {
          state: selectedStateName,
          ageGroup: selectedAgeGroup,
          year: selectedYear
        }
      })
        .then(response => {
          const topModes = response.data.top_5.reduce((acc, mode, index) => {
            acc[mode] = response.data.top_5_values[index];
            return acc;
          }, {});
          setTopContactModes(topModes);
        })
        .catch(error => {
          console.error("Error fetching top contact modes:", error);
        });
    }
  }, [selectedStateName, selectedAgeGroup, selectedYear]);

  const modeIcons = {
    'Email': '📧',
    'Text message': '💬',
    'Social media/Online forums': '👥',
    'Mobile apps': '📲',
    'Internet': '🌐',
    'Phone Call': '📞',
    'In person': '🧑‍🤝‍🧑',
    'Mail': '📫',
    'Other': '❓',
  };

  return (
    <div className="contact-mode-container">
      <h2><FaShieldAlt /> Top 5 Scam Contact Methods</h2>
      <p className="subtitle">Most common ways scammers reach out in {selectedStateName}</p>
      <div className="contact-mode-grid">
        {Object.entries(topContactModes).map(([mode, count]) => (
          <div key={mode} className="contact-mode-box">
            <span className="icon">{modeIcons[mode] || '❓'}</span>
            <span className="mode-name">{mode}</span>
            <span className="count">{count} reports</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactModeIcons;