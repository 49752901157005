// src/components/ScrollToTopButton.js
import React from 'react';
import './ScrollToTopButton.css';
import UpArrowImage from './uparrow.png'; // Adjust the path based on your folder structure

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  };

  // Inline styles for the scroll-to-top button
  const buttonStyle = {
    position: 'fixed',
    bottom: '30px',
    left: '30px',
    width: '40px',
    height: '40px',
    // backgroundColor: '#1d72b8',
    // border: '2px solid #000',
    // borderRadius: '8px',
    cursor: 'pointer',
    zIndex: 10000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s ease',
  };

  const buttonHoverStyle = {
    backgroundColor: '#155a8a',
    transform: 'scale(1.1)',
  };

  const arrowIconStyle = {
    width: '40px',
    height: '40px',
  };

  return (
    <button
      style={buttonStyle}
      className="scroll-to-top-button"
      onClick={scrollToTop}
      onMouseOver={(e) => (e.currentTarget.style.transform = buttonHoverStyle.transform)}
      onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
    >
      <img src={UpArrowImage} alt="Scroll to top" style={arrowIconStyle} />
    </button>
  );
};

export default ScrollToTopButton;
