// src/pages/MapView.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './MapView.css';
import DonutChart from './DonutChart';
import StackedBarChart from './stackedBarChart';
import ContactModeIcons from './ContactModeIcons';
import ScrollArrowButton from '../components/ScrollArrowButton'; // Import the new ScrollArrowButton component

function ColorScaleBar({ colorScale, min, max }) {
  const map = useMap();

  useEffect(() => {
    const colorScaleControl = L.control({ position: 'bottomright' });

    colorScaleControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info color-scale-bar');
      const grades = [min, (min + max) / 2, max];
      const gradient = `linear-gradient(to top, ${grades.map(g => colorScale(g)).join(', ')})`;

      div.innerHTML = '<strong>Reports</strong><br>';
      div.innerHTML += `
          <div style="display: flex; align-items: center; height: 400px;">
            <div style="height: 100%; width: 20px; background: ${gradient};"></div>
            <div style="display: flex; flex-direction: column; justify-content: space-between; width: 50px; height: 100%; font-size: 12px; margin-left: 5px;">
              <div>${Math.round(max)}</div>
              <div>${Math.round((min + max) / 2)}</div>
              <div>${Math.round(min)}</div>
            </div>
          </div>
        `;
      return div;
    };

    colorScaleControl.addTo(map);

    return () => {
      colorScaleControl.remove();
    };
  }, [map, colorScale, min, max]);

  return null;
}

function MapView({ initialStateData }) {
  const [geoJSONData, setGeoJSONData] = useState(null);
  const [stateData, setStateData] = useState(initialStateData);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [colorScaleMin, setColorScaleMin] = useState(0);
  const [colorScaleMax, setColorScaleMax] = useState(1000);
  const [selectedStateCode, setSelectedStateCode] = useState(null);
  const [selectedStateName, setSelectedStateName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(4.4);
  const [showContactModes, setShowContactModes] = useState(false);
  const [showDownArrow, setShowDownArrow] = useState(false); // State to handle down arrow visibility
  const [scrollTarget, setScrollTarget] = useState('selected-state-info');

  
  
  // Add state for showing stacked bar chart
  const [showStackedBarChart, setShowStackedBarChart] = useState(false);

  // Fetch GeoJSON and filter options on component mount
  useEffect(() => {
    fetch('/data/australia_states.geojson')
      .then(response => response.json())
      .then(data => setGeoJSONData(data))
      .catch(error => console.error('Error fetching GeoJSON data:', error));

    // Fetch years for filtering
    axios.get(`${process.env.REACT_APP_API_URL}/scamreports/filter-options/`)
      .then(response => {
        console.log("Filter options response:", response.data); // Debugging log
        setYears(response.data.years);
      })
      .catch(error => console.error('Error fetching filter options:', error));

    // Fetch initial state data without any filter
    fetchStateData();
  }, []);

  // Function to fetch state data based on selected year
  const fetchStateData = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/scamreports/aggregated/`, {
      params: { year: selectedYear }
    })
    .then(response => {
      console.log("API Response:", response.data); // Log the entire response
      const stateData = response.data.state_data;
  
      if (!stateData || !stateData.state) {
        console.error("State data is missing or malformed:", stateData);
        setLoading(false);
        return;
      }
  
      const transformedData = stateData.state.map((state, index) => ({
        state_code: stateData.state_code[index],
        state: state,
        report_count: stateData.report_count[index],
        total_amount_lost: stateData.total_amount_lost[index]
      }));
  
      console.log("Transformed Data:", transformedData); // Log transformed data
      setLoading(false);
      setStateData(transformedData);
      setColorScaleMin(response.data.min_reports);
      setColorScaleMax(response.data.max_reports);
    })
    .catch(error => {
      console.error('Error fetching state data:', error);
      setLoading(false);
    });
  };

  // Function to determine color based on report count
  const getColor = (reports) => {
    return reports > colorScaleMax * 0.8 ? '#003f5c' :
           reports > colorScaleMax * 0.6 ? '#2f4b7c' :
           reports > colorScaleMax * 0.4 ? '#465a7e' :
           reports > colorScaleMax * 0.2 ? '#6c8bb2' :
                                           '#d0e3f1';
  };

  // Function to get style for each feature
  const getStyle = (feature) => {
    const stateCode = feature.properties.ste_iso3166_code;
    const stateInfo = stateData.find(state => state.state_code === stateCode);
    const reportCount = stateInfo ? stateInfo.report_count : 0;

    return {
      fillColor: getColor(reportCount),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
    };
  };

  // Function to bind popup information for each feature
  const onEachFeature = (feature, layer) => {
    const stateCode = feature.properties.ste_iso3166_code;
    const stateInfo = stateData.find(state => state.state_code === stateCode);
 
    const reportCount = stateInfo ? stateInfo.report_count.toLocaleString() : 'N/A';
    const stateName = stateInfo ? stateInfo.state : 'N/A';
 
    layer.bindPopup(`
       <b>${stateName}</b><br>
       Reports: ${reportCount}
    `);
 
    layer.on({
       click: () => {
          setSelectedStateCode(stateCode);
          setSelectedStateName(stateName);
          setShowDownArrow(true); // Show the down arrow when a state is selected
       }
    });
  };

 

   // Apply filters based on selected year
   const handleApplyFilter = () => {
     fetchStateData();
   };

   // Clear filters and reset states
   const handleClearFilter = () => {
     setSelectedYear('');
     fetchStateData(); // Re-fetch all data without filters
     setSelectedStateName(null);
   };

   // Show more information about the selected state
   const handleShowMoreInfo = () => {
     setShowStackedBarChart(prev => !prev);
   };

   const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const offset = 120; // Adjust this value based on the height of your floating navigation bar
  
    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
  
      // Update the scroll target to the next section after reaching the first one
      if (sectionId === 'selected-state-info') {
        setScrollTarget('more-info-section');
      } else if (sectionId === 'more-info-section') {
        setScrollTarget('selected-state-info');
      } else if (sectionId === 'selected-state-info') {
        
      }
    }
  };

   return (
    <div className="past-scam-major-header-section">
      <div className="past-scam-header-section">
        <div className="intro-text-header-section">
          <div className="intro-text-content">
            <h1>Awareness of Employment Scams Are Increasing</h1>
            <p className="fade-in-text">We've seen an increase in the number of reported employment scams.</p>
            <p className="fade-in-text">By staying informed, you are contributing to the fight against scams and helping others stay protected.</p>
            <button className="explore-button" onClick={() => scrollToSection('filter-section')}>
                Explore the Map
              </button>
          </div>
          <div id="intro-video-container" className="intro-video-container">
            <img src="/trends_.jpg" alt="Intro Image" className="intro-video" />
          </div>
        </div>
        
        <div id="container" className="container">
          <div id="filter-section" className="filter-section">
            <div className="filter-group">
              <label>Year:</label>
              <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                <option value="">All</option>
                {years.map(year => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div id="button-group" className="button-group">
              <button onClick={handleApplyFilter}>Apply Filter</button>
              <button onClick={handleClearFilter}>Clear Filter</button>
            </div>
          </div>

          {loading ? (
            <div className="loading">
              <p>Loading data to help you stay informed and safe...</p>
              <div className="loading-animation"></div>
            </div>
          ) : (
            <>
              <div className="map-container">
                <div id="scam-reports-section" style={{ textAlign: 'center' }}>
                  <h2>Understanding Scam Reports Across Regions</h2>
                  <p>Curious about scams in your area? Click on a state to see gender-wise scam stats!</p>
                </div>
                {geoJSONData && (
                  <MapContainer
                    center={[-25.2744, 133.7751]}
                    zoom={zoomLevel}
                    style={{ height: "600px", width: "100%", backgroundColor: "white" }}
                    scrollWheelZoom={false}
                    dragging={false}
                    touchZoom={false}
                    doubleClickZoom={false}
                    zoomControl={false}
                  >
                    <GeoJSON
                      key={JSON.stringify(geoJSONData)}
                      data={geoJSONData}
                      style={getStyle}
                      onEachFeature={onEachFeature}
                    />
                    <ColorScaleBar colorScale={getColor} min={colorScaleMin} max={colorScaleMax} />
                  </MapContainer>
                )}

              </div>
 {/* Use ScrollArrowButton to handle the scrolling between sections */}
 {showDownArrow && (
            <ScrollArrowButton scrollTarget={scrollTarget} setScrollTarget={setScrollTarget} />
          )}
              {selectedStateName && (
              <div id="selected-state-info" className="selected-state-info">
                <DonutChart
                  state={selectedStateName}
                  year={selectedYear}
                />
                <div id="more-info-section" className="more-info-section">
                <h2 style={{ fontSize: '24px', fontWeight: 'bold', margin: '20px 0', textAlign: 'center' }}>🔍 Want to dig deeper?</h2>
                <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '10px' }}>Click below to uncover the top 5 methods scammers use!</p>
                <button className="cta-button" onClick={() => setShowContactModes(prev => !prev)}>
                {showContactModes ? '🙈 Hide the Contact Modes' : '🔍 Show top 5 Contact Modes'}
                  </button>
                  {showContactModes && (
                    <>
                      <ContactModeIcons 
                        selectedStateName={selectedStateName}
                        year={selectedYear}
                      />

                      <h2 style={{ fontSize: '24px', fontWeight: 'bold', margin: '20px 0', textAlign: 'center' }}>🤔 Still curious?</h2>
                      <p style={{ fontSize: '18px', textAlign: 'center', marginBottom: '10px' }}>Click below to reveal detailed chart of contact methods by age group!</p>

                      <button className="cta-button" onClick={handleShowMoreInfo}>
                        {showStackedBarChart ? '🙈 Hide Age-Wise Pattern' : '📊 Show Age-Wise Pattern'}
                        </button>

                      {showStackedBarChart &&(
                        
                        <StackedBarChart
                          state={selectedStateName}
                          year={selectedYear}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default MapView;