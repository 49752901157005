import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './HomePageNew.css';
import ScrollToTopButton from '../components/ScrollToTopButton';

const HomePageNew = () => {
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event triggered');
      console.log('Current scroll position:', window.scrollY);

      if (window.scrollY > 500) {
        setIsScrollButtonVisible(true);
      } else {
        setIsScrollButtonVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to handle smooth scrolling to the next section
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const offset = 80; // Adjust this value to the height of your floating navigation bar
  
    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    }
  };

  const services = [
    {
      title: "Validate Job Listing",
      description: "Check the legitimacy of job postings with our advanced AI-powered tool. Stay safe from scams and fraudulent opportunities.",
      link: "/jobdescriptionanalysis",
      image: "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      title: "Browse Safely",
      description: "Enhance your online job search with our CareerTrust Chrome Extension. Get real-time alerts about potential phishing URLs.",
      link: "/chrome-extension",
      image: "/extension.jpg", // Replace with the appropriate image path
    },
    
    {
      title: "Discover Job Opportunities",
      description: "Explore a curated list of verified, safe job opportunities. Start your career journey with confidence and security.",
      link: "/job-opportunities",
      image: "/job_opp.jpg",
    },
    {
      title: "Support for scam victims",
      description: "Our dedicated support page is designed to assist victims of job scams by providing valuable resources and guidance. Explore our resources and let us support you in navigating the aftermath of a scam.",
      link: "/report-scam",
      image: "/support.jpg", // Replace with the appropriate image path
    },
    {
      title: "Stay Scam Smart",
      description: "Test and improve your knowledge with our interactive scam awareness quiz. Learn to spot red flags and protect yourself.",
      link: "/trivia",
      image: "/trivia.jpg",
    },
    {
      title: "Explore Past Scams",
      description: "Stay informed by reviewing and learning from past scams in Australia. Knowledge is your best defense against fraud.",
      link: "/pastscams",
      image: "/trends_.jpg",
    }
  ];


  return (
    <div className="hero-background">
      {/* Hero Section */}
      <header className="hero-section" id="hero-section">
  <div className="overlay"></div>
  <div className="hero-content">
    <h1>Stop Job Scams in Their Tracks</h1>
    <p>Empowering stay-at-home parents and students seeking jobs across Australia</p>
    <a href="/jobdescriptionanalysis" className="get-started-btn">Check your Job Posting</a>
    <a
  href="#user-journey"
  className="explore-link"
  onClick={(e) => {
    e.preventDefault(); // Prevent the default anchor behavior
    scrollToSection('user-journey');
  }}
>
  Explore More
</a>

  </div>
{/* Unique Scroll Down Arrow */}
<div className="hero-scroll-arrow" onClick={() => scrollToSection('user-journey')}>
  <img src={require('./down_white.png')} alt="Scroll Down" className="arrow-image" />
</div>
</header>


      {/* User Journey Section */}
      <section id="user-journey" className="user-journey">
        <h2>Your Journey to Safe Job Hunting</h2>
        <div className="journey-container">
          <p className="journey-intro">Follow these steps to ensure a safe and secure job search experience.</p>
          <div className="journey-row">
            <Link to="/jobdescriptionanalysis" className="journey-step">
              <div className="step-icon">📋</div>
              <h3>ANALYZE</h3>
              <p>Check job descriptions for scams</p>
            </Link>
            <div className="dot"></div>
            <Link to="/chrome-extension" className="journey-step">
              <div className="step-icon">🛡️</div>
              <h3>PROTECT</h3>
              <p> Use extension to block evil websites</p>
            </Link>
            <div className="dot"></div>
            <Link to="/job-opportunities" className="journey-step">
              <div className="step-icon">🔍</div>
              <h3>DISCOVER</h3>
              <p>Find similar trusted job postings</p>
            </Link>
          </div>
          <p className="journey-intro">Encountered scams or want to be informed, follow these steps for further assistance.</p>
          <div className="journey-row">
            <Link to="/report-scam" className="journey-step">
              <div className="step-icon">⚠️</div>
              <h3>REPORT</h3>
              <p>Report scams and get support</p>
            </Link>
            <div className="dot"></div>
            <Link to="/trivia" className="journey-step">
              <div className="step-icon">📚</div>
              <h3>LEARN</h3>
              <p>Access our educational resources</p>
            </Link>
            <div className="dot"></div>
            <Link to="/pastscams" className="journey-step">
              <div className="step-icon">📊</div>
              <h3>EXPLORE</h3>
              <p>Review historical scam trends</p>
            </Link>
          </div>
        </div>

        {/* Scroll Down Arrow */}
<div className="scroll-arrow" onClick={() => scrollToSection('empowerment-section')}>
  <img src={require('./downarrow.PNG')} alt="Scroll Down" className="arrow-image" />
</div>

{/* Empowerment Section */}
<div id="empowerment-section" className="empowerment-section">
        <h2 className="section-title">CareerTrust Shield Empowers Job Seekers with:</h2>
        <ul className="animate-list">
          <li>Detection of fake job postings</li>
          <li>Identification of malicious websites</li>
          <li>A web browser extension for protection</li>
          <li>Amy, your personal job search assistant</li>
          <li>Interactive tools and resources to increase scam awareness</li>
          <li>Tracking scam trends through our dashboard</li>
        </ul>
      </div>
{/* Scroll Down Arrow */}
<div className="scroll-arrow" onClick={() => scrollToSection('services-section')}>
  <img src={require('./downarrow.PNG')} alt="Scroll Down" className="arrow-image" />
</div>
      </section>

      {/* Services Section */}
      <div id="services-section" className="services-section">
      <h2 className="section-title">Explore Our Services</h2>
        {services.map((service, index) => (
          <div key={index} className={`service-item ${index % 2 === 0 ? 'left' : 'right'}`}>
            <div className="service-shape" style={{ backgroundColor: service.color }}>
              <img src={service.image} alt={service.title} className="service-image" />
            </div>
            <div className="service-content">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
              <Link to={service.link} className="service-link">Learn More</Link>
            </div>
          </div>
        ))}
      </div>

      {/* Scroll-to-Top Button */}
      {isScrollButtonVisible && <ScrollToTopButton />}
    </div>
  );
};

export default HomePageNew;
