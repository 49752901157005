import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DonutChart.css';

const DonutChartTextBox = ({ state, ageGroup, year, contactMode }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (state) {
      fetchData(state, ageGroup, year, contactMode);
    }
  }, [state, ageGroup, year, contactMode]);

  const fetchData = async (state, ageGroup, year, contactMode) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/scamreports/donut_chart/`, {
        params: { state, ageGroup, year, contactMode },
      });
      setChartData(response.data.chart_data);
    } catch (error) {
      console.error('Error fetching chart data:', error);
      setError('Oops! We hit a snag. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="summary-container">Gathering insights to keep you informed...</div>;
  if (error) return <div className="summary-container">{error}</div>;
  if (chartData.length === 0) return <div className="summary-container">No data available for {state} at the moment. Stay tuned!</div>;

  return (
    <div className="summary-container">
      <h2>
        <span role="img" aria-label="gender">⚖️</span>
        Gender-Wise Scam Report Summary for {state}
      </h2>
      <p className="empowerment-message">Understanding how scams affect different genders empowers us all. Here's what we found:</p>
      {chartData.map((entry, index) => (
        <div key={index} className="summary-entry">
          <span className="gender-icon" style={{ color: entry.gender === 'Male' ? '#3498db' : entry.gender === 'Female' ? '#e74c3c' : '#9b59b6', marginRight: '8px' }}>
            {entry.gender === 'Male' ? '♂️' : entry.gender === 'Female' ? '♀️' : '⚧️'}
          </span>
          <h3 style={{ display: 'inline-block', margin: 0 }}>{entry.gender}</h3> {/* Inline with text */}
          <p className="report-count">{entry.report_count} reports filed</p>
          <p className="amount-lost">Total reported loss: <strong>${(entry.amount_lost / 1000000).toFixed(2)}M</strong></p>
        </div>
      ))}
      <p className="empowerment-message">Your reports are powerful tools in the fight against scams. Together, we're building a safer community!</p>
    </div>
  );
};

export default DonutChartTextBox;