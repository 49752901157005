// src/components/ScrollArrowButton.js
import React, { useEffect, useState } from 'react';
import './ScrollArrowButton.css';
import downArrowImage from './downarrow.PNG'; // Make sure this path is correct based on your project structure

const ScrollArrowButton = ({ scrollTarget, setScrollTarget }) => {
  const [atMoreInfoSection, setAtMoreInfoSection] = useState(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const offset = 120; // Adjust this value based on the height of your floating navigation bar

    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });

      // Update the scroll target to the next section after reaching the first one
      if (sectionId === 'selected-state-info') {
        setScrollTarget('more-info-section');
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const moreInfoSection = document.getElementById('more-info-section');
      if (moreInfoSection) {
        const moreInfoSectionPosition = moreInfoSection.getBoundingClientRect().top + window.pageYOffset;
        const scrollPosition = window.pageYOffset + window.innerHeight / 2;

        if (scrollPosition >= moreInfoSectionPosition) {
          setAtMoreInfoSection(true);
        } else {
          setAtMoreInfoSection(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (atMoreInfoSection) {
    return null; // Hide the down arrow when the user is at the "more-info-section"
  }

  return (
    <div className="down-arrow" onClick={() => scrollToSection(scrollTarget)}>
      <img src={downArrowImage} alt="Scroll Down" className="arrow-image" />
    </div>
  );
};

export default ScrollArrowButton;
