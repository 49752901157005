import React, { useState } from 'react';
import FlipCardList from '../components/FlipCardList';
import Quiz from '../components/Quiz';
import './Trivia.css';
import NextStepButton from '../components/NextStepButton';

const Trivia = () => {
  const [view, setView] = useState('flip'); // State to toggle between flip cards and quiz view

  // Function to handle smooth scrolling to the next section with offset
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const offset = 120; // Adjust this value to the height of your floating navigation bar

    if (section) {
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: sectionPosition, behavior: 'smooth' });
    }
  };

  return (
    <div>
      {/* Header Section */}
      <div className="job-description-header-section">
        <div className="job-analysis-container">
          <div className="intro-text-header-section">
            <div className="intro-text-content">
              <h1>JOB SCAM SHIELD: PROTECT YOUR CAREER</h1>
              <h4>Why Job Scam Awareness Matters</h4>
              <p className="fade-in-text">
                <i className="fas fa-shield-alt"></i> Protect Your Information: Safeguard your personal and financial details from scammers.<br />
                <i className="fas fa-check-circle"></i> Save Time: Avoid wasting time on fraudulent job postings.<br />
                <i className="fas fa-search"></i> Build Confidence: Knowing what to look for helps you navigate better.<br />
                <i className="fas fa-clipboard-check"></i> Spread Awareness: Help others stay safe.
              </p>
              {/* Update the link to use the scrollToSection function */}
              <button className="explore-button" onClick={() => scrollToSection('button-section')}>
                Go to Trivia
              </button>
            </div>
            <div id="intro-video-container" className="intro-video-container">
              <img className="intro-video" src="/trivia.jpg" alt="Descriptive Alt Text" />
            </div>
          </div>
        </div>
      </div>

      {/* Button Section */}
      <div id="button-section" className="button-section">
        <button 
          className={`toggle-button ${view === 'flip' ? 'active' : ''}`}
          onClick={() => setView('flip')}
        >
          Flip
        </button>
        <button 
          className={`toggle-button ${view === 'quiz' ? 'active' : ''}`}
          onClick={() => setView('quiz')}
        >
          Test Your Knowledge
        </button>
      </div>

      {/* Main Content in White Container */}
      <div className="white-container">
        <div id="description-section" className="description-section">
          {view === 'flip' ? (
            <p>
              <strong>Are you aware of the red flags that indicate a job scam?</strong> <br/>
              Our flip cards provide essential information to recognize common scams and protect yourself. Click to flip and reveal important details.
            </p>
          ) : (
            <p>
              <strong>Think You Can Spot a Job Scam?</strong> <br/>
              Test your knowledge with our trivia quiz! Answer the questions and see how well you understand job scams.
            </p>
          )}
        </div>

        {/* Flip Cards or Quiz */}
        <div className="main-content">
          {view === 'flip' ? <FlipCardList /> : <Quiz />}
        </div>
      </div>
      <NextStepButton 
        nextPage="/pastscams" 
        buttonText="Explore Scam Reports in Your Region" 
      />
    </div>
  );
};

export default Trivia;
