import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePageNew from './pages/HomePageNew';
import PasswordProtect from './PasswordProtect';
import Trivia from './pages/Trivia';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop'; 
import Dashboard from './components/Dashboard';
import Chatbot from './components/ChatBot';
import PastScams from './pages/PastScams';
import JobOpportunities from './pages/JobOpportunities';
import JobDescriptionAnalysisPage from './pages/JobDescriptionAnalysisPage';
import Testpage from './pages/Testpage';
import ReportScam from './pages/ReportScam'; 
import ChromeExtension from './pages/ChromeExtension';
import NotFound from './pages/NotFound';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is already authenticated by looking for the token in localStorage
    const token = localStorage.getItem('access_token');
    if (token) {
      setAuthenticated(true);
    }
  }, []);

  const handleAuthentication = (auth) => {
    if (auth) {
      localStorage.setItem('access_token', 'authenticated');
      setAuthenticated(true);
    }
  };

  return (
    <Router>
      <ScrollToTop />  {/* Ensure ScrollToTop is outside of Routes */}
      {authenticated && <Navbar />}  {/* Navbar is displayed only when authenticated */}
      
      <Routes>
        {authenticated ? (
          <>
            <Route path="/" element={<HomePageNew />} />
            <Route path="/home" element={<HomePageNew />} />
            <Route path="/trivia" element={<Trivia />} />
            {/* <Route path="/past-scams" element={<Dashboard />} /> */}
            <Route path="/pastscams" element={<PastScams />} />
            <Route path="/jobdescriptionanalysis" element={<JobDescriptionAnalysisPage />} />
            <Route path="/job-opportunities" element={<JobOpportunities />} />
            <Route path="/report-scam" element={<ReportScam />} />
            <Route path="/chrome-extension" element={<ChromeExtension />} />
            
            {/* 404 Not Found route should come last */}
            <Route path="*" element={<NotFound />} /> 
          </>
        ) : (
          <>
            <Route
              path="*"
              element={
                <PasswordProtect onAuthenticated={handleAuthentication} />
              }
            />
          </>
        )}
      </Routes>

      {/* Use location hook inside Router */}

      {authenticated && <ConditionalChatbot />}
      <Footer />
    </Router>
  );
}

// Create a component that uses useLocation inside the Router context
function ConditionalChatbot() {
  const location = useLocation();
  const [isChatbotVisible, setIsChatbotVisible] = useState(true);

  useEffect(() => {
    // Reset the scroll visibility logic when the route changes
    if (location.pathname === '/' || location.pathname === '/home') {
      const handleScroll = () => {
        if (window.scrollY > 500) {
          setIsChatbotVisible(true);
        } else {
          setIsChatbotVisible(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Check scroll position on mount

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      // Ensure chatbot is visible on all other pages except /jobdescriptionanalysis
      setIsChatbotVisible(location.pathname !== '/jobdescriptionanalysis');
    }
  }, [location.pathname]);

  // Conditionally render the Chatbot based on the visibility state
  return isChatbotVisible ? <Chatbot /> : null;
}


export default App;
