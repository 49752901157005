import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './JobOpportunities.css';
import NextStepButton from '../components/NextStepButton'

const JobOpportunities = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const [trustRating, setTrustRating] = useState(null);
  const [redFlags, setRedFlags] = useState('');
  const [error, setError] = useState('');

  // Fetch jobs when component is mounted
  useEffect(() => {
    fetch(
      `https://api.adzuna.com/v1/api/jobs/au/search/1?app_id=268598ba&app_key=26f4b72e199de6e0d16c23255298c1a2`
    )
      .then((response) => response.json())
      .then((data) => {
        setJobs(data.results);
      })
      .catch((error) => console.error('Error fetching jobs:', error));
  }, []);

  const handleSearch = () => {
    fetch(
      `https://api.adzuna.com/v1/api/jobs/au/search/1?app_id=268598ba&app_key=26f4b72e199de6e0d16c23255298c1a2&what=${keyword}&where=${location}`
    )
      .then((response) => response.json())
      .then((data) => {
        setJobs(data.results);
        setSelectedJob(null); // Clear selected job if new search
      })
      .catch((error) => console.error('Error fetching jobs:', error));
  };

  const handleSelectJob = (job) => {
    setSelectedJob(job);
    setTrustRating(null); // Clear previous trust rating and red flags
    setRedFlags('');
  };

  const handleCalculateTrustRating = async () => {
    if (!selectedJob) {
      setError('Please select a job to analyze.');
      return;
    }

    // Prepare the form data
    const formData = new FormData();
    formData.append('text', selectedJob.description);

    setError('');
    setTrustRating(null);
    setRedFlags('');

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/scamreports/job_description/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setTrustRating(response.data.trust_rating);
      setRedFlags(response.data.red_flags);  // Set red flags from response

    } catch (err) {
      setError('Error analyzing job description. Please try again.');
    }
  };

  return (
    <div className="job-opportunities-page">
      <h1>Find Your Next Job Opportunity</h1>
      <p>Use the search fields below to look for job opportunities in your desired location and industry.</p>

      <div className="job-search">
        <input
          type="text"
          placeholder="Job Title/Keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
       <button onClick={handleSearch}>
          <i className="fas fa-search" style={{ color: 'white' }}></i> Find Jobs
        </button>
      </div>

      <div className="content">
        <div className="job-list">
          {jobs.length > 0 ? (
            jobs.map((job, index) => (
              <div key={index} className="job-item" onClick={() => handleSelectJob(job)}>
                <h3><i className="fas fa-briefcase"></i> {job.title}</h3>
                <p>{job.description.slice(0, 100)}...</p>
              </div>
            ))
          ) : (
            <p>No jobs found. Try a different search.</p>
          )}
        </div>

        <div className="job-details">
          {selectedJob ? (
            <>
              <h2><i className="fas fa-briefcase"></i> {selectedJob.title}</h2>
              <p><strong>Job Description:</strong> {selectedJob.description.slice(0, 600)}</p>
              <p><strong>Location:</strong> <i className="fas fa-map-marker-alt"></i> {selectedJob.location.display_name}</p>
              <p><strong>Company:</strong> {selectedJob.company.display_name}</p>
              <button onClick={() => window.open(selectedJob.redirect_url, '_blank')}>
                <i className="fas fa-external-link-alt"></i> Apply on Adzuna
              </button>

              {trustRating !== null && (
                <div className="analysis-result">
                  <h4>
                    <center>
                      Job Trust Rating:
                      <span style={{ color: trustRating > 80 ? 'green' : 'red', fontWeight: 'bold', fontSize: '1.7rem' }}>
                        {trustRating}%
                      </span>
                    </center>
                  </h4>
                </div>
              )}

              {redFlags && (
                <div className="red-flags">
                  <h4><center>Red Flags Detected:</center></h4>
                  <p>{redFlags}</p>
                </div>
              )}

              {error && <div className="error">{error}</div>}

              {trustRating !== null && (
                <div className="warning-message">
                  <p >
                    <center>
                      <strong>
                        This is calculated based on partial Job Description. If you want the exact details then copy-paste full job description in the Job Description Analysis page.
                      </strong>
                    </center>
                  </p>
                </div>
              )}
            </>
          ) : (
            <p>Select a job to see details</p>
          )}
        </div>
      </div>
      <NextStepButton 
                nextPage="/report-scam" 
                buttonText="Report Suspicious Job Postings" 
            />
    </div>
  );
};

export default JobOpportunities;
