import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0); 
  // }, [pathname]);
  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    
    console.log('Triggering scroll to top');
    
    body.scrollTop = 0; // For Safari
    html.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  }, [pathname]);
  return null;
};

export default ScrollToTop;
