import React from 'react';
import JobDescriptionAnalysis from '../components/JobDescriptionAnalysis';
import NextStepButton from '../components/NextStepButton'

const JobDescriptionAnalysisPage = () => {
    return (
        <div>
            <JobDescriptionAnalysis />
            <NextStepButton 
                nextPage="/chrome-extension" 
                buttonText="Download Chrome Extension to Stay Safe" 
            />
        </div>
    );
};

export default JobDescriptionAnalysisPage;