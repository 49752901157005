import React from 'react';
import { Link } from 'react-router-dom';
import icon from './cryingamy.png'; // Ensure the path is correct to your image

const NotFound = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f5',
      color: '#333',
      padding: '20px',
    }}>
      {/* Image on the left */}
      <div style={{ paddingRight: '20px' }}>
        <img 
          src={icon} 
          alt="404 Error" 
          style={{ 
            maxWidth: '400px',
            height: 'auto',
            borderRadius: '15px'
          }} 
        />
      </div>

      {/* Text on the right */}
      <div style={{ maxWidth: '500px' }}>
        <h1 style={{
          fontSize: '3.0em',
          background: 'linear-gradient(to right, #0056b3, #00b3e6)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          marginBottom: '0.5em',
          textShadow: '2px 2px rgba(0, 0, 139, 0.3)',
        }}>
          Uh-oh! Page Extinct!
        </h1>
        <p style={{
          fontSize: '1.5em',
          color: '#0056b3',
          marginBottom: '1em'
        }}>
          Looks like this page is no longer around.
        </p>
        <p style={{ fontSize: '1.2em', color: '#333' }}>
          No worries! Let’s get you back to the <Link to="/" style={{ color: '#ff6b6b', textDecoration: 'underline' }}>Home Page</Link>.
        </p>
        <p style={{ fontSize: '1.2em', marginTop: '1em', color: '#888' }}>
          Or you could just hang out with Amy!
        </p>
      </div>
    </div>
  );
};

export default NotFound;
